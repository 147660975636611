import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import {Typography,List,ListItem} from '@material-ui/core';


const Privacy = () => {
    return (
        <Layout>
                <Heading>Privacy Policy</Heading>
                {/* <Typography variant='body1'>Last updated: February 25, 2020</Typography> */}

                <Typography variant='body1'>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</Typography>

                <Typography variant='body1'>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</Typography>

                <h1>Interpretation and Definitions</h1>
                <h2>Interpretation</h2>
                <Typography variant='body1'>The words of which the initial letter is capitalized have meanings defined under the following conditions.</Typography>
                <Typography variant='body1'>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>

                <h2>Definitions</h2>
                <Typography variant='body1'>For the purposes of this Privacy Policy:</Typography>
                <List>
                    <ListItem>
                        <Typography variant='body1'><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</Typography>
                            </ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Dermatology Consultants of Asheville, PLLC, 218 Elkwood Ave Ste 104.</Typography>
                            </ListItem>
                        <ListItem><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</ListItem>
                    <ListItem><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</ListItem>
                    <ListItem><strong>Website</strong> refers to Dermatology Consultants of Asheville, accessible from https://dermatologyasheville.com</ListItem>        <ListItem><strong>Service</strong> refers to the Website.</ListItem>
                        <ListItem><strong>Country</strong> refers to: North Carolina, United States</ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</Typography>
                            </ListItem>
                    <ListItem><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</ListItem>
                        <ListItem>
                        <Typography variant='body1'><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</Typography>
                                <Typography variant='body1'>For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.</Typography>    </ListItem>
                    <ListItem><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</ListItem>        <ListItem><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</ListItem>
                        <ListItem><strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</ListItem>        <ListItem><strong>Business</strong>, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.</ListItem>
                    <ListItem><strong>Consumer</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</ListItem>
                    <ListItem><strong>Sale</strong>, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s Personal information to another business or a third party for monetary or other valuable consideration.</ListItem>
                </List>

                <h1>Collecting and Using Your Personal Data</h1>
                <h2>Types of Data Collected</h2>

                <h3>Personal Data</h3>
                <Typography variant='body1'>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</Typography>
                <List>
                    <ListItem>Usage Data</ListItem>
                </List>


                <h3>Usage Data</h3>
                <Typography variant='body1'>Usage Data is collected automatically when using the Service.</Typography>
                <Typography variant='body1'>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</Typography>
                <Typography variant='body1'>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</Typography>
                <Typography variant='body1'>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</Typography>



                <h3>Tracking Technologies and Cookies</h3>
                <Typography variant='body1'>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</Typography>
                <Typography variant='body1'>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</Typography>
                <Typography variant='body1'>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</Typography>
                <Typography variant='body1'>We use both session and persistent Cookies for the purposes set out below:</Typography>
                <List>
                    <ListItem>
                        <Typography variant='body1'><strong>Necessary / Essential Cookies</strong></Typography>
                        <Typography variant='body1'>Type: Session Cookies</Typography>
                        <Typography variant='body1'>Administered by: Us</Typography>
                        <Typography variant='body1'>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>Cookies Policy / Notice Acceptance Cookies</strong></Typography>
                        <Typography variant='body1'>Type: Persistent Cookies</Typography>
                        <Typography variant='body1'>Administered by: Us</Typography>
                        <Typography variant='body1'>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>Functionality Cookies</strong></Typography>
                        <Typography variant='body1'>Type: Persistent Cookies</Typography>
                        <Typography variant='body1'>Administered by: Us</Typography>
                        <Typography variant='body1'>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</Typography>
                    </ListItem>
                        <ListItem>
                        <Typography variant='body1'><strong>Tracking and Performance Cookies</strong></Typography>
                        <Typography variant='body1'>Type: Persistent Cookies</Typography>
                        <Typography variant='body1'>Administered by: Third-Parties</Typography>
                        <Typography variant='body1'>Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.</Typography>
                    </ListItem>
                </List>
                <Typography variant='body1'>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.</Typography>

                <h2>Use of Your Personal Data</h2>
                <Typography variant='body1'>The Company may use Personal Data for the following purposes:</Typography>
                <List>
                    <ListItem><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</ListItem>
                    <ListItem><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</ListItem>
                    <ListItem><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</ListItem>
                    <ListItem><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</ListItem>
                    <ListItem><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</ListItem>
                    <ListItem><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</ListItem>
                </List>

                <Typography variant='body1'>We may share your personal information in the following situations:</Typography>

                <List>
                    <ListItem><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to contact You, to advertise on third party websites to You after You visited our Service or for payment processing.</ListItem>
                    <ListItem><strong>For Business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</ListItem>
                    <ListItem><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</ListItem>
                    <ListItem><strong>With Business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</ListItem>
                    <ListItem><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</ListItem>
                </List>

                <h2>Retention of Your Personal Data</h2>
                <Typography variant='body1'>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Typography>
                <Typography variant='body1'>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</Typography>

                <h2>Transfer of Your Personal Data</h2>
                <Typography variant='body1'>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</Typography>
                <Typography variant='body1'>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</Typography>
                <Typography variant='body1'>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</Typography>

                <h2>Disclosure of Your Personal Data</h2>
                <h3>Business Transactions</h3>
                <Typography variant='body1'>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</Typography>
                <h3>Law enforcement</h3>
                <Typography variant='body1'>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</Typography>
                <h3>Other legal requirements</h3>
                <Typography variant='body1'>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</Typography>
                <List>
                    <ListItem>Comply with a legal obligation</ListItem>
                    <ListItem>Protect and defend the rights or property of the Company</ListItem>
                    <ListItem>Prevent or investigate possible wrongdoing in connection with the Service</ListItem>
                    <ListItem>Protect the personal safety of Users of the Service or the public</ListItem>
                    <ListItem>Protect against legal liability</ListItem>
                </List>

                <h2>Security of Your Personal Data</h2>
                <Typography variant='body1'>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</Typography>

                <h1>Detailed Information on the Processing of Your Personal Data</h1>
                <Typography variant='body1'>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.</Typography>

                <h2>Analytics</h2>
                <Typography variant='body1'>We may use third-party Service providers to monitor and analyze the use of our Service.</Typography>
                <List>
                    <ListItem>
                        <Typography variant='body1'><strong>Google Analytics</strong></Typography>
                        <Typography variant='body1'>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</Typography>
                        <Typography variant='body1'>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</Typography>                <Typography variant='body1'>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></Typography>
                    </ListItem>
                </List>

                <h2>Behavioral Remarketing</h2>
                <Typography variant='body1'>The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.</Typography>
                <List>
                    <ListItem>
                        <Typography variant='body1'><strong>Google Ads (AdWords)</strong></Typography>
                        <Typography variant='body1'>Google Ads (AdWords) remarketing service is provided by Google Inc.</Typography>
                        <Typography variant='body1'>You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a></Typography>
                        <Typography variant='body1'>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</Typography>
                        <Typography variant='body1'>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></Typography>
                    </ListItem>
                </List>

                <h1>CCPA Privacy</h1>
                <h2>Your Rights under the CCPA</h2>
                <Typography variant='body1'>Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:</Typography>
                <List>
                    <ListItem><strong>The right to notice.</strong> You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</ListItem>
                    <ListItem><strong>The right to access / the right to request.</strong> The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party’s direct marketing purposes.</ListItem>
                    <ListItem><strong>The right to say no to the sale of Personal Data.</strong> You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our "Do Not Sell My Personal Information" section or web page.</ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>The right to know about Your Personal Data.</strong> You have the right to request and obtain from the Company information regarding the disclosure of the following:</Typography>
                        <List>
                            <ListItem>The categories of Personal Data collected</ListItem>
                            <ListItem>The sources from which the Personal Data was collected</ListItem>
                            <ListItem>The business or commercial purpose for collecting or selling the Personal Data</ListItem>
                            <ListItem>Categories of third parties with whom We share Personal Data</ListItem>
                            <ListItem>The specific pieces of Personal Data we collected about You</ListItem>
                        </List>
                    </ListItem>
                    <ListItem><strong>The right to delete Personal Data.</strong> You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months.</ListItem>
                    <ListItem>
                        <Typography variant='body1'><strong>The right not to be discriminated against.</strong> You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by:</Typography>
                        <List>
                            <ListItem>Denying goods or services to You</ListItem>
                            <ListItem>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</ListItem>
                            <ListItem>Providing a different level or quality of goods or services to You</ListItem>
                            <ListItem>Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.</ListItem>
                        </List>
                    </ListItem>
                </List>

                <h2>Exercising Your CCPA Data Protection Rights</h2>
                <Typography variant='body1'>In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us or visit our "Do Not Sell My Personal Information" section or web page.</Typography>
                <Typography variant='body1'>The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.</Typography>

                <h2>Do Not Sell My Personal Information</h2>
                <Typography variant='body1'>We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA law.</Typography>
                <Typography variant='body1'>If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.</Typography>
                <Typography variant='body1'>Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.</Typography>

                <h3>Website</h3>
                <Typography variant='body1'>You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:</Typography>
                <List>
                <ListItem>From Our "Cookie Consent" notice banner</ListItem>
                <ListItem>Or from Our "CCPA Opt-out" notice banner</ListItem>
                <ListItem>Or from Our "Do Not Sell My Personal Information" notice banner</ListItem>
                <ListItem>Or from Our "Do Not Sell My Personal Information" link</ListItem>
                </List>
                <Typography variant='body1'>The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.</Typography>

                <h3>Mobile Devices</h3>
                <Typography variant='body1'>Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:</Typography>
                <List>
                <ListItem>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</ListItem>
                <ListItem>"Limit Ad Tracking" on iOS devices</ListItem>
                </List>
                <Typography variant='body1'>You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.</Typography>

                <h1>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</h1>
                <Typography variant='body1'>Our Service does not respond to Do Not Track signals.</Typography>
                <Typography variant='body1'>However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</Typography>

                <h1>Children's Privacy</h1>
                <Typography variant='body1'>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</Typography>
                <Typography variant='body1'>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these users.</Typography>
                <Typography variant='body1'>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</Typography>


                <h1>Links to Other Websites</h1>
                <Typography variant='body1'>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</Typography>
                <Typography variant='body1'>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Typography>

                <h1>Changes to this Privacy Policy</h1>
                <Typography variant='body1'>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</Typography>
                <Typography variant='body1'>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</Typography>
                <Typography variant='body1'>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>

                <h1>Contact Us</h1>
                <Typography variant='body1'>If you have any questions about this Privacy Policy, You can contact us:</Typography>
                <List>
                    <ListItem>By email: office@dermatologyasheville.com</ListItem>
                    <ListItem>By phone number: 828.484.4478</ListItem>
                </List>
        </Layout> 
    )
};

export default Privacy;